'use client';

import { useTranslations } from 'next-intl';
import Link from 'next/link';
export function VRAppDownloadSection() {
  const t = useTranslations('home');
  return <section id="VRAppDownloadSection" className="grid place-items-center bg-black-background w-full sm:h-screen h-[80vh]" data-sentry-component="VRAppDownloadSection" data-sentry-source-file="VRAppDownloadSection.tsx">
      <div className="max-w-2xl px-4 mx-auto text-center sm:px-6 lg:px-8 z-20">
        <span className="font-[Roboto] sm:text-6xl text-3xl font-medium text-white">
          {' '}
          OVERAY
        </span>
        <span className="font-[Roboto] sm:text-6xl text-3xl font-thin text-white">
          {' '}
          STUDIO
        </span>
        <h2 className="mt-12 text-2xl font-bold tracking-tight text-white sm:text-4xl ">
          <span className="block sm:text-4xl text-2xl">
            {t('vr_app_download_section_title')} <br />
            {t('vr_app_download_section_title2')}
          </span>
        </h2>

        <Link href="https://www.oculus.com/deeplink/?action=view&path=app/7028370967196772&ref=oculus_desktop" className="inline-flex items-center justify-center w-full sm:px-16 px-6 ssm:px-4 py-3 mt-8 sm:text-xl text-sm font-bold bg-overay-800 border border-transparent rounded-md text-white hover:bg-overay-700" data-sentry-element="Link" data-sentry-source-file="VRAppDownloadSection.tsx">
          {t('vr_app_download_section_button')}
        </Link>
        <p className="mt-2 font-bold leading-6 text-sm text-overay-200">
          {t('vr_app_download_section_button_sub')}
        </p>
      </div>
    </section>;
}
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/.yarn/__virtual__/next-intl-virtual-0f9ce18a7d/0/cache/next-intl-npm-3.26.3-1cc2a0a74d-1f1bb4830d.zip/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/.yarn/__virtual__/next-intl-virtual-0f9ce18a7d/0/cache/next-intl-npm-3.26.3-1cc2a0a74d-1f1bb4830d.zip/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/unplugged/next-virtual-50517f2fa6/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layouts/SiteLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DownloadSection"] */ "/app/src/components/Main/DownloadSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/app/src/components/Main/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VirtualMonitorSection"] */ "/app/src/components/Main/VirtualMonitorSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VirtualSpaceSection"] */ "/app/src/components/Main/VirtualSpaceSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VRAppDownloadSection"] */ "/app/src/components/Main/VRAppDownloadSection.tsx");

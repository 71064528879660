'use client';

import { VIDEO_ENDPOINTS } from '@/lib/constants/resources';
import { authorizationAtom } from '@/store/authorization-atom';
import { useAtom } from 'jotai';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
const DynamicVideoComponent = dynamic(async () => await import('@/components/UI/VideoSection').then(mod => mod.VideoSection), {
  ssr: false
});
export function Hero() {
  const t = useTranslations('home');
  const buttonClick = (url: string) => {
    return () => {
      window.open(url, '_blank');
    };
  };
  const signUpLink = `${process.env.ACCOUNT_OVERAY_STUDIO ?? ''}/accounts/signup`;
  const [isAuthorized] = useAtom(authorizationAtom);

  // fix hydration error when user log-in
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return <section className="relative w-full h-full bg-black-background" data-sentry-component="Hero" data-sentry-source-file="Hero.tsx">
      <div className="absolute mx-auto sm:pt-0 pt-10 w-full lg:max-w-7xl h-full inset-x-0 z-0 bg-black-background overflow-hidden">
        <DynamicVideoComponent className="lg:absolute w-full lg:w-full mt-8 md:mt-12 lg:mt-24 mx-auto lg:-right-72" source={VIDEO_ENDPOINTS.OVERAY_HERO} data-sentry-element="DynamicVideoComponent" data-sentry-source-file="Hero.tsx" />
      </div>
      <div className="relative sm:pl-20 pl-5 ssm:pl-3 pt-60 bg-transparent z-0">
        <div className="mx-auto w-full pt-40 sm:pt-60 md:pt-80 lg:pt-0 md:max-w-3xl lg:max-w-7xl pb-48 sm:pb-48 md:60 lg:pb-96">
          <div>
            <h1 className="text-4xl font-bold tracking-tight md:text-left md:text-6xl text-white">
              {t('hero_title')}
            </h1>
            <p className="w-105 mt-6 max-w-2xl font-light md:text-2xl text-base leading-8 text-white md:text-left">
              {t('hero_sub')}
              <br />
              {t('hero_sub2')} <br />
            </p>
            <div className="mt-8 flex gap-x-4 sm:justify-left">
              <button onClick={buttonClick('https://www.youtube.com/watch?v=Hpy5NWisRaQ')} className="inline-block rounded-lg bg-transparent px-4 py-1.5 text-base font-semibold leading-7 text-white border border-gray-300 hover:bg-gray-800 transition duration-300">
                {t('hero_demo_video')}
                <span className="ml-2 text-overay-100" aria-hidden="true">
                  &rarr;
                </span>
              </button>
              {isClient && !isAuthorized && <button onClick={buttonClick(signUpLink)} className="inline-block rounded-lg bg-overay-800 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-overay-700">
                  {t('hero_start_free')}
                  <span className="ml-2 text-gray-50" aria-hidden="true">
                    &rarr;
                  </span>
                </button>}
            </div>
          </div>
        </div>
      </div>
    </section>;
}
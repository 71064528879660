'use client';

import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import { VIDEO_ENDPOINTS } from '../../lib/constants/resources';
const DynamicVideoComponent = dynamic(async () => await import('@/components/UI/VideoSection').then(mod => mod.VideoSection), {
  ssr: false
});
export function VirtualMonitorSection() {
  const t = useTranslations('home');
  return <section className="relative min-h-6xl bg-red pt-24 bg-black-background " data-sentry-component="VirtualMonitorSection" data-sentry-source-file="VirtualMonitorSection.tsx">
      <div id="VirtualMonitorSection" className="relative w-full h-screen min-h-[400px] sm:min-h-[600px] md:min-h-[800px] lg:min-h-[1000px] max-h-[600px] md:max-h-[1200px] lg:max-h-[1600px] mx-auto text-center">
        <DynamicVideoComponent className="absolute bottom-0 left-1/2 origin-center -translate-x-1/2 sm:min-w-[800px] w-full sm:h-auto max-h-full" source={VIDEO_ENDPOINTS.VIRTUAL_MONITOR} data-sentry-element="DynamicVideoComponent" data-sentry-source-file="VirtualMonitorSection.tsx" />
        <div className="absolute top-0 left-0 w-full h-full z-1 pt-12 md:pt-16 lg:pt-24">
          <h2 className="text-xl font-semibold text-white ">
            {t('virtual_monitors_section_top')}
          </h2>
          <p className="mt-2 md:text-5xl text-2xl font-bold tracking-tight text-white">
            {t('virtual_monitors_section_title')}
          </p>
          <p className="mx-auto p-5 mt-5 font-light md:text-2xl text-base sm:w-auto w-10/12 text-white max-w-prose">
            {t('virtual_monitors_section_sub')}
            <br />
            {t('virtual_monitors_section_sub2')}
          </p>
        </div>
      </div>
    </section>;
}
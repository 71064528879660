'use client';

import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import { IMAGE_ENDPOINTS } from '../../lib/constants/resources';
export function DownloadSection() {
  const t = useTranslations('home');
  const downloadLink = 'https://overay.sharepoint.com/:u:/s/Product-Distribution/EQGnzPox2bRFrB1B8jtrQsIBDSs5O4uMWgQmyYvRR0lbuw?download=1';
  return <section className="relative pt-24 overflow-hidden bg-black-background sm:pt-32 lg:pt-40" data-sentry-component="DownloadSection" data-sentry-source-file="DownloadSection.tsx">
      <div id="DownloadSection" className="relative flex flex-row justify-center max-w-6xl mx-auto">
        <div className="max-w-4xl sm:mt-6 mb-6 w-4xl grow mx-auto">
          <Image className="mx-auto rounded-lg" priority={true} src={IMAGE_ENDPOINTS.DESKTOP_HOST_1} width={1344} height={908} alt="Download PC Host" data-sentry-element="Image" data-sentry-source-file="DownloadSection.tsx" />
        </div>
      </div>
      <div className="max-w-2xl px-2 py-16 mx-auto text-center sm:py-20 sm:px-4 lg:px-0">
        <p className="mt-2 text-2xl pb-12 font-bold tracking-tight text-white md:text-6xl">
          {t('download_section_title')}
        </p>
        <h2 className="text-sm font-light tracking-tight text-white sm:text-2xl ssm:text-sm">
          <span className="block">
            {t('download_section_sub')}
            {'\n'}
          </span>
          <span className="block"> {t('download_section_sub2')}</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-white"></p>
        <Link href={downloadLink} className="inline-flex items-center justify-center w-10/12 md:px-16 px-6 py-3 md:mt-8 mt-4 sm:text-lg text-sm font-bold bg-overay-800 border border-transparent rounded-md text-white hover:bg-overay-700 sm:w-auto" data-sentry-element="Link" data-sentry-source-file="DownloadSection.tsx">
          {t('download_section_button')}
        </Link>
        <p className="mt-2 font-bold leading-6 text-md text-overay-200">
          {t('download_section_button_sub')}
        </p>
      </div>
    </section>;
}
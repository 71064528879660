'use client';

import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import { VIDEO_ENDPOINTS } from '../../lib/constants/resources';
const DynamicVideoComponent = dynamic(async () => await import('@/components/UI/VideoSection').then(mod => mod.VideoSection), {
  ssr: false
});
export function VirtualSpaceSection() {
  const t = useTranslations('home');
  return <section className="relative pt-24 overflow-hidden sm:pt-32 lg:pt-40 bg-black-background h-full " data-sentry-component="VirtualSpaceSection" data-sentry-source-file="VirtualSpaceSection.tsx">
      <div className="max-w-md sm:px-6 px-1 mx-auto text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
        <div>
          <h2 className="text-xl font-semibold text-white">
            {t('virtual_spaces_section_top')}
          </h2>
          <p className="mt-2 font-bold tracking-tight text-white md:text-5xl text-2xl">
            {t('virtual_spaces_section_title')}
          </p>
          <p className="mx-auto mt-5 md:pb-5 font-light md:text-2xl text-base text-white max-w-prose">
            {t('virtual_spaces_section_sub')}
            <br />
            {t('virtual_spaces_section_sub2')}
          </p>
        </div>
        <div className="sm:mt-5 mt-16 justify-center">
          <DynamicVideoComponent source={VIDEO_ENDPOINTS.VIRTUAL_SPACES} className="rounded-lg" data-sentry-element="DynamicVideoComponent" data-sentry-source-file="VirtualSpaceSection.tsx" />
        </div>
      </div>
    </section>;
}